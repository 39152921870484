
.text
	display: flex
	flex-wrap: wrap
	width: calc(100% + 50px)
	margin-left: -50px
	margin-top: 140px
	padding-bottom: 60px
	+r(992)
		margin-top: 80px
	+r(768)
		// margin-left: 0
		margin-bottom: 20px
	+r(544)
		margin-left: 0
		width: 100%
	&__item
		position: relative
		display: inline-block
		width: calc(25% - 50px)
		margin-left: 50px
		margin-bottom: 120px
		vertical-align: top
		+r(992)
			width: calc(50% - 50px)
			margin-bottom: 80px
		+r(768)
			margin-bottom: 40px
		+r(544)
			width: 100%
			margin-left: 0
		&.mod_1-3
			width: calc(33.33% - 50px)
			+r(768)
				width: 100%
		&.mod-wide
			+r(992)
				width: 100%
				height: 200px
			+r(768)
				height: 140px
			+r(544)
				height: 100px
		.cross-link
			margin-bottom: 0
			margin-top: 80px
			+r(768)
				margin: 40px 0 0

	&__top
		margin-bottom: 25px
		+r(992)
			margin-bottom: 15px
	&__num
		font-size: 60px
		color: $red
		line-height: 1
		+bb
		+r(992)
			font-size: 40px
	&__pic
		position: relative
		display: inline-block
		width: 60px
		height: 60px
		background-color: rgba(255, 255, 255, .4)
		border-radius: 100%
	&__img
		+vcenter
	&__title
		font-size: 24px
		line-height: 1.2
		letter-spacing: -.5px
		color: #fff
		margin-bottom: 16px
		+bb
		+r(992)
			font-size: 17px
		&.mod_blue
			color: #00214d
	&__desc
		font-size: 16px
		line-height: 1.5
		letter-spacing: -.2px
		opacity: .85
		color: #fff
		+br
		+r(992)
			font-size: 14px
		&.mod_blue
			color: #00214d

