.startscreen
	&__soc
		position: absolute
		right: 30px
		bottom: 10%
		width: 30px
		z-index: 10
	.soc__link
		display: block
		vertical-align: top
		text-align: right
		margin-bottom: 40px
		fill: #fff
		+tr(.4s)
		&:hover
			fill: $red
		svg
			width: 16px
			height: 15px
	#soc
		display: inline-block
		vertical-align: top