
+font('Bond4FBold', 'Bond4FBold')
+font('Bond4FSemiBold', 'Bond4FSemiBold')
+font('Bond4FMedium', 'Bond4FMedium')
+font('Bond4F', 'Bond4F')
+font('Bond4FLight', 'Bond4FLight')
+font('Bond4FUltraLight', 'Bond4FUltraLight')

=bul
	font-family: 'Bond4FUltraLight', Arial, sans-serif
=bl
	font-family: 'Bond4FLight', Arial, sans-serif
=br
	font-family: 'Bond4F', Arial, sans-serif
=bm
	font-family: 'Bond4FMedium', Arial, sans-serif
=bsb
	font-family: 'Bond4FSemiBold', Arial, sans-serif
=bb
	font-family: 'Bond4FBold', Arial, sans-serif
