
.screen
	// padding-top: 1px
	// min-height: 100vh
	+r(700)
		height: auto !important

	&__wrap
	&__title
		width: 50%
		max-width: 555px
		padding-top: 120px
		+r(992)
			padding-top: 80px
			width: 100%
		+r(768)
			padding-top: 60px
		+r(544)
			font-size: 29px
		&.mod_wide
			width: 100%
			max-width: 750px
	&__text
		display: flex
		justify-content: space-between
	&-last
		position: relative