
.header
	position: fixed
	top: 0
	right: 0
	left: 0
	// background-color: $blue
	height: 98px
	z-index: 100
	+tr(.4s)
	&.is-scroll
		height: 60px
		background-color: $blue
	&__wrap
		display: flex
		align-items: center
		justify-content: space-between
		height: 100%
	&__logo_icon
		position: relative
		display: inline-block
		vertical-align: top
		height: 30px
		z-index: 100
		+tr(.4s)
		&:hover
			opacity: .8

#red
	fill: red
.clip_10
	fill: #fff

.enter
	display: flex
	align-items: center
	justify-content: space-between
	+r(544)
		display: none
	&__link
		margin-left: 40px
		color: #fff
		font-size: 16px
		line-height: 1.25
		letter-spacing: -.4px
		+bb
		+tr(.4s)
		&:hover
			color: $red

.select
	position: relative
	display: inline-block
	width: 76px
	cursor: pointer
	font-size: 0
	white-space: nowrap
	z-index: 500
	z-index: 10
	&:after
		content: ""
		position: absolute
		top: 50%
		right: 0px
		transform: translateY(-50%)
		transform-origin: 50% 50%
		+arr(10, 5, #fff, b)
		+tr(.4s 0s)
	&.is-active
		z-index: 1000
		&:after
			top: 45%
			right: 10px
			+rotate(180)

	&__pic
		position: relative
		width: 16px
		height: 16px
		display: inline-block
		vertical-align: top
		overflow: hidden
		border-radius: 100%
	&__img
		+vcenter
	&__text
		display: flex
		align-items: center
		justify-content: center
		position: relative
		height: 36px
		color: #000
		padding: 0 0 0 10px
		vertical-align: middle
		letter-spacing: -.025em
		// line-height: 36px
		-webkit-touch-callout: none
		-webkit-user-select: none
		-khtml-user-select: none
		-moz-user-select: none
		-ms-user-select: none
		user-select: none
		+r(544)
			padding: 0 20px 0 0
		p
			display: none!important
		span
			font-size: 15px
			margin: 0 5px
			line-height: 1.3
			text-transform: uppercase
			color: #fff
			+box
	&__list
		position: absolute
		display: none
		left: 50%
		top: 40px
		width: 160px
		text-align: center
		margin: 0
		padding: 0
		list-style-type: none
		border-radius: 0 0 4px 4px
		background: #fff
		+trx(-50%)
		&:before
			content: ''
			+coverdiv
			z-index: -5
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2)

	&__link
		position: relative
		width: 100%
		display: flex
		align-items: center
		justify-content: center
		font-size: 14px
		line-height: 36px
		color: #000
		cursor: pointer
		transition: background-color .8s, border .8s, color .4s
		&:hover
			background-color: $red
			color: #fff
	p
		font-size: 13px
		display: inline-block
		vertical-align: top
		margin: 0 5px
	b
		line-height: .8
		+bb

	&__row
		position: relative
		width: 100%
		font-size: 14px
		line-height: 36px
		padding-left: 10px
		color: #000
		cursor: pointer
		-webkit-transition: background 0.2s linear
		-moz-transition: background 0.2s linear
		-o-transition: background 0.2s linear
		transition: background 0.2s linear
		+tr(.4s)

		.btn
			margin-top: 20px
	&__all
		display: inline-block
		color: $red
		line-height: .8

		&:hover
			border-color: $n

.menu
	position: relative
	&__icon
		display: none
		position: absolute
		top: 8px
		right: 0px
		width: 20px
		height: 18px
		cursor: pointer
		+r(544)
			display: inline-block
			top: -8px
		&:before,
		&:after
			content: ''
			position: absolute
			left: 0
			right: 0
			height: 50%
			+box
			transform-origin: 50% 50%
		&:before
			top: 0
			border-top: 2px solid #fff
			border-bottom: 1px solid #fff
		&:after
			bottom: 0
			border-top: 1px solid #fff
			border-bottom: 2px solid #fff
		&.is-active
			&:before
				border-bottom: none
				top: 40%
				+rotate(45)
			&:after
				border-top: none
				bottom: 40%
				+rotate(-45)
	&__soc
		display: none
	&.is-active
		position: fixed
		top: 0
		right: 0
		bottom: 0
		left: 0
		background-color: $blue
		z-index: 20
		.menu__soc
			text-align: center
			display: block
			margin-top: 40px
			margin-left: -40px
		.menu__icon
			top: 24px
			right: 24px
			&:before
				border-bottom: none
				top: 40%
				+rotate(45)
			&:after
				border-top: none
				bottom: 40%
				+rotate(-45)
		.enter
			display: flex
			align-items: center
			justify-content: center
			flex-direction: column
			margin-top: 40%
			&__wrap
				display: block
			&__link
				display: block
				text-align: center
				margin: 30px 0 0
		.soc__link
			width: 20px
			margin-left: 40px
			svg
				width: 20px
				fill: #fff
